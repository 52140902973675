import * as React from 'react';
import { ErrorMessagePage } from '@app/components/error-message-page';

const Page404: React.FC = () => {
  return (
    <ErrorMessagePage
      title="Page not found (404)"
      message="The page you are looking for does not exist."
      backAs="/"
      backLink="/index"
      backLabel="Head back to home"
    />
  );
};

export default Page404;
