import * as React from 'react';
import { Typography, Button } from '@material-ui/core';
import { UndecoratedLink } from '@0soft/zero-web';
import Link from 'next/link';
import styled from 'styled-components';

interface OwnProps {
  title?: string;
  message?: string;
  backLink?: string;
  backAs?: string;
  backLabel?: string;
  backFn?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;

const Subtitle = styled.div`
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(4)}px;
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorMessagePage: React.FunctionComponent<OwnProps> = ({
  title,
  message,
  backLink,
  backAs,
  backLabel,
  backFn,
}) => {
  return (
    <Container>
      <Title>{title && <Typography variant="h1">{title}</Typography>}</Title>
      <Subtitle>{message && <Typography variant="h2">{message}</Typography>}</Subtitle>
      <Back>
        {backLabel != null && backLink != null && (
          <Link href={backLink} as={backAs} passHref>
            <UndecoratedLink>
              <Button variant="contained" color="primary">
                {backLabel}
              </Button>
            </UndecoratedLink>
          </Link>
        )}
        {backFn != null && backLabel != null && (
          <Button variant="contained" color="primary" onClick={backFn}>
            {backLabel}
          </Button>
        )}
      </Back>
    </Container>
  );
};
